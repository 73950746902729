<template>
  <el-dialog
    title="授权"
    :close-on-click-modal="false"
    :visible.sync="visible"
    center
    width="800px"
    class="toGrantAuthorization"
  >
    <div class="formPs">授权涉及到了按钮的话请重新登陆</div>
    <el-tree
      :data="menuList"
      :props="menuListTreeProps"
      node-key="menuId"
      ref="menuListTree"
      :default-expand-all="true"
      show-checkbox
    >
    </el-tree>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="visible=false">取 消</el-button>
      <el-button
        type="primary"
        @click="dataFormSubmit()"
      >确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      menuList: [],
      menuListTreeProps: {
        label: "name",
        children: "list",
      },
      dataForm: {},
    };
  },
  created() {},
  methods: {
    init(id) {
      this.visible = true;
      if (id) {
        let roleId = "";
        try {
          roleId = JSON.parse(localStorage.getItem("predicthealthUserInfo"))
            .sysRoleList[0].roleId;
        } catch (err) {
          roleId = JSON.parse(localStorage.getItem("predicthealthUserInfo"))
            .roleId;
        }
        this.$http
          .get(
            `admin/sys-role-menu/getByRoleId/${roleId}?isAdmin=${
              this.getUserId() == 1 ? true : false
            }&newRoleId=${id}`
          )
          .then((res) => {
            if (res.data.code == 200) {
              let data = res.data.data;
              this.$set(this.dataForm, "roleId", id);
              let list = [];
              this.getRoleMenuList(data, list);
              this.$refs.menuListTree.setCheckedKeys(list);
              this.menuList = data;
            }
          });
      }
    },
    /**
     * 递归 获取授权菜单
     */
    getRoleMenuList(data, list) {
      data.forEach((item) => {
        if (item.list.length > 0) {
          this.getRoleMenuList(item.list, list);
        } else if (item.isAuthorization) list.push(item.menuId);
      });
    },
    // 表单提交
    dataFormSubmit() {
      let url = "admin/sys-role-menu/saveOrUpdateRole";
      let data = this.dataForm;
      let menuId = [].concat(
        this.$refs.menuListTree.getCheckedKeys(),
        this.$refs.menuListTree.getHalfCheckedKeys()
      );
      data.menuId = menuId;
      if (data.menuId.length == 0)
        this.$message({
          message: "请选择授权的菜单",
          type: "warning",
        });
      else {
        this.$http.post(`${url}`, data).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.visible = false;
            // location.reload();
          } else {
            this.$message({
              message: res.data.msg,
              type: "warning",
            });
          }
        });
      }
    },
  },
};
</script>

<style lang='less'>
.toGrantAuthorization {
  .el-dialog__body {
    overflow-y: auto;
    height: calc(100vh - 500px);
  }
}
</style>
