<template>
  <el-dialog
    :title="dataForm.roleId?'修改':'新增'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    center
    width="800px"
  >
    <el-form
      ref="dataForm"
      :rules="dataRule"
      :model="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="80px"
    >
      <el-form-item
        label="角色名称"
        prop="roleName"
      >
        <el-input
          v-model="dataForm.roleName"
          placeholder="请输入角色名称"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="入口页"
        prop="indexUrl"
      >
        <el-input
          v-model="dataForm.indexUrl"
          placeholder="请输入入口页"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="备注"
        prop="remark"
      >
        <el-input
          v-model="dataForm.remark"
          placeholder="请输入备注"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="排序"
        prop="sort"
      >
        <el-input-number
          placeholder="排序"
          v-model="dataForm.sort"
          :min='1'
        ></el-input-number>
      </el-form-item>

    </el-form>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="visible=false">取 消</el-button>
      <el-button
        type="primary"
        @click="dataFormSubmit()"
      >确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      dataForm: {},
      dataRule: {
        roleName: [
          { required: true, message: "该项为必填项", trigger: "blur" },
        ],
      },
    };
  },
  created() {},
  methods: {
    init(id, parentId) {
      this.visible = true;
      this.dataForm = {};
      if (id) {
        this.$http.get(`admin/sys-role/baseGetById/${id}`).then((res) => {
          if (res.data.code == 200) {
            let data = res.data.data;
            if (data) this.dataForm = data;
          }
        });
      } else if (parentId) this.$set(this.dataForm, "parentId", parentId);
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          let url = "admin/sys-role/saveOrUpdate";
          let data = this.dataForm;
          if (data.roleId) delete data.createTime;
          this.$http.post(`${url}`, data).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                message: "操作成功",
                type: "success",
              });
              this.visible = false;
              this.$emit("refreshDataList");
            } else {
              this.$message({
                message: res.data.msg,
                type: "warning",
              });
            }
          });
        }
      });
    },
  },
};
</script>

<style lang='less' scoped>
</style>
